/** @jsx jsx */

import { FC } from 'react'
import { Box, jsx, Container, Text, Flex, Image, NavLink } from 'theme-ui'

import Halo from '../../components/halo'
import Intro from '../../components/intro'
import Layout from '../../components/layout'
import Navigation from '../../components/navig'

import { AboutSidebar } from './'

export interface AboutBrand {
  data: any
}
const AboutBrand: FC<AboutBrand> = ({ data }) => {
  return (
    <Layout>
      <Navigation />
      <Box variant="box.plateBig" sx={{ px: 4 }}>
        <Halo title="Brand" url="https://nft.rage.fan/about" />
        <Container>
          <Box>
            <Flex variant="box.plateMedium2" sx={{ color: 'text', pt: 7, pb: 6 }}>
              <AboutSidebar />
              <Box>
                <Intro title="Functonary Brand" description="" />
                <Text as="p" variant="body" sx={{ color: 'grays.9', fontWeight: 100, fontSize: 3 }}>
                  Indelible Ink Brand uses
                </Text>
                <Box sx={{ bg: 'grays.0', p: 0, mt: 6 }}>
                  <Box sx={{ mb: 3, pb: 3, p: 4, borderBottom: 'solid 1px #eee' }}>
                    <Flex>
                      <Image
                        sx={{ width: '2rem', height: '2rem' }}
                        src="//s.svgbox.net/hero-outline.svg?fill=444&ic=document-download"
                      />
                      <Box sx={{ pl: 4 }}>
                        <NavLink href="//">Brand Guidelines</NavLink>
                        <Text sx={{ fontSize: 1, color: 'grays.6' }}>PDF(21MB)</Text>
                      </Box>
                    </Flex>
                  </Box>
                  <Box sx={{ mb: 3, pb: 3, p: 4, borderBottom: 'solid 1px #eee' }}>
                    <Flex>
                      <Image
                        sx={{ width: '2rem', height: '2rem' }}
                        src="//s.svgbox.net/hero-outline.svg?fill=444&ic=document-download"
                      />
                      <Box sx={{ pl: 4 }}>
                        <NavLink href="//">Brand Kit</NavLink>
                        <Text sx={{ fontSize: 1, color: 'grays.6' }}>ZIP (21MB)</Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default AboutBrand
